import Navbar from './components/Navbar';
import Home from './components/Home';
import Services from './components/Services';
import Reviews from './components/Reviews';
import Disinfection from './components/Disinfection';
import Pricing from './components/Pricing';
import ContactUs from './components/ContactUs';
import Footer from './components/Footer';
import AboutUs from './components/AboutUs';

function App() {
  return (
    <div>
      <Navbar />
      <Home />
      <AboutUs />
      <Services />
      <Disinfection />
      <Reviews />
      <Pricing />
      <ContactUs />
      <Footer />
    </div>
  );
}

export default App;
