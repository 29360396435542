import React from 'react';

export default function ReviewsItems(props) {
  return (
    <div className="business_testi_content text-center mx-auto mt-3">
      <p className="business_testi_details mt-4 ">{props.paragraph}</p>
      <div className="">
        <ul className="list-unstyled mb-0">
          <li className="list-inline-item">
            <i className="mdi mdi-star text_custom"></i>
          </li>
          <li className="list-inline-item">
            <i className="mdi mdi-star text_custom"></i>
          </li>
          <li className="list-inline-item">
            <i className="mdi mdi-star text_custom"></i>
          </li>
          <li className="list-inline-item">
            <i className="mdi mdi-star text_custom"></i>
          </li>
          <li className="list-inline-item">
            <i className="mdi mdi-star text_custom"></i>
          </li>
        </ul>
      </div>
      <p className="mt-2 mb-0 text-white"> {props.name}</p>
      <span className="text-white">{props.type}</span>
    </div>
  );
}
