import React from 'react';
import DidinfectionItem from './DisinfectionItem';

export default function Disinfection(props) {
  return (
    <section className="section_all text-centern toto " id="desinfection">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="section_title_all text-center">
              {/* <div className="section_icons">
                <i className="mbri-extension"></i>
              </div> */}
              <h3 className="mt-3">
                {' '}
                <span className="text_custom"> Disinfection </span>
              </h3>
              <p className="section_subtitle mx-auto text-muted">
                Certified commercial and residential disinfection service to eliminate contamination from viruses.
                Certified and trained with an emphasis on SARS-Co V-2/COVID. We are using EPA register products to kill
                germs and reduce risk of spreading infection. The product meets the EPA's emerging pathogens
                requirements, showing efficacy against enveloped and nonenveloped viruses.
              </p>
            </div>
          </div>
        </div>

        <div className="row mt-5">
          <DidinfectionItem img="Logo/GBAC.jpeg" />
          <DidinfectionItem img="/Logo/image3.jpg" />
          <DidinfectionItem img="/Logo/image0.jpg" />
          <DidinfectionItem img="/Logo/IMG_5731.jpg" />
          <DidinfectionItem img="/Logo/IMG_6413.jpg" />
          <DidinfectionItem img="/Logo/image4.jpg" />
          <DidinfectionItem img="/Logo/image2.jpg" />
          <DidinfectionItem img="/Logo/disen2.jpg" />
        </div>
      </div>
    </section>
  );
}
