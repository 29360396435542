import React from 'react';

export default function AboutUs() {
  return (
    <section style={{ paddingTop: '16%' }} className="section_all toto" id="aboutUs">
      <div className="container">
        <div className="row vertical_content_manage">
          <div className="col-lg-7">
            <div className="app_description_header mt-3">
              <h3 className="text-capitalize font-weight-normal">About Us...</h3>
              {/* <h6 className="text-capitalize font-weight-normal"> A clean home is a happy home.</h6> */}
              {/* <p className="text-muted" style={{ marginBlockEnd: '0em' }}>
                Brilliant Cleaning service was co-founded by two good friends who
                decided to offer the best professional cleaning service to help busy families clean their homes.
              </p> */}
              <p style={{ marginBlockEnd: '0em' }} className="text-muted"></p>
              <p className="text-muted" style={{ marginBlockEnd: '0em' }}>
                <span style={{ fontWeight: 'bold' }}>Brilliant Cleaning Service</span> was co-founded by two good
                friends who decided to offer the best professional cleaning service to help busy families clean their
                homes. We have been in the cleaning business for almost 20 years, serving the Chicago area and suburbs.
                We pride ourselves for the best quality service we offer, professional and highly trained cleaning
                staff, and the ability to listen to your needs. We are ready to help create a cleaner and healthier
                environment for you and your family. Make your life less stressful with a high quality house cleaning.
                Come back home, relax, and feel like you're on vacation. We are ready to fit in your needs and would
                like to meet your requirements.
              </p>
              <p className="text-muted" style={{ marginBlockEnd: '0em' }}>
                Please give us a call and let us know how we may service you.
              </p>
              <p className="text-muted" style={{ marginBlockEnd: '0em' }}>
                We are here to help!
              </p>
            </div>
          </div>
          <div className="col-lg-5">
            <div className="mt-3">
              <img src="Logo/small.png" alt="" className="img-fluid mx-auto d-block" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
