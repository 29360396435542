import React from 'react';

export default function DisinfectionItem(props) {
  return (
    <div className="col-lg-3 work_item ">
      <div className=" p-6 bg-light rounded">
        <a className="img-zoom" href={props.img}>
          <div className="work_box">
            <div className="work_img">
              <img
                style={{ height: '200px' }}
                src={props.img}
                className="img-fluid mx-auto d-block rounded"
                alt={props.alt}
              />
            </div>
            {/* <div className="work_detail">
            <h5 className="mb-0">{props.text}</h5>
          </div> */}
          </div>
        </a>
      </div>
    </div>
  );
}
