import React from 'react';

export default function Pricing() {
  return (
    <section style={{ paddingTop: '10%', paddingBottom: '10%' }} className="section_all bg-light toto" id="pricing">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="section_title_all text-center">
              <div className="section_icons">
                <i className="mbri-like"></i>
              </div>
              <h3 className="mt-3">
                Our <span className="text_custom"> Pricing </span>
              </h3>
              <p className="section_subtitle mx-auto ">Estimates are always free for our prospective clients.</p>
              {/* <p className="section_subtitle mx-auto ">We Are Here to Help!</p> */}
              <p className="section_subtitle mx-auto ">Give us a call, text or email us.</p>
              <p className="section_subtitle mx-auto ">
                We would like to see your place and give you a quote. It's always free! We will have a chance to talk
                about your needs. Our prices are reasonable and definitely we can fit in your budget. Same day cleaning
                service for residential, construction and commercial businesses are also available.
              </p>
              {/* <h4 className="section_subtitle mx-auto  text-muted">
                We Are Here to Help!
              </h4> */}
              {/* <h6 className="section_subtitle mx-auto  text-muted">
                Give us a call, text or email.
                After we conduct the audit, we’ll be able to give you a detailed and accurate cost based on your actual cleaning needs.
                Our prices are reasonable and definitely we can fit in your budget. Last minutes  emergency cleaning service. Give us a call,
                there’s always a chance we can still squeeze you in. Even if we can’t fit you in today, we can likely schedule you for the next day.
              </h6> */}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
