import React from 'react';

export default function Navbar() {
  return (
    <nav className="navbar navbar-expand-lg fixed-top custom_nav_menu sticky">
      <div className="container">
        {/* <h1 >Brilliant Service</h1> */}
        <a className="navbar-brand logo" href="/">
          {/* <img src="Logo/small.png" alt="" className="img-fluid " /> */}
          <img src="Brilliant-Logo.png" alt="" className="img-fluid " />
          {/* <img src="Logo/mediumsmall.png" alt="" className="img-fluid logo-dark" /> */}
        </a>

        {/* <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
          <i className="mdi mdi-menu"></i>
        </button> */}
        <div className="collapse navbar-collapse" id="navbarCollapse">
          <ul className="navbar-nav mx-auto">
            <li className="nav-item active">
              <a href="#home" className="nav-link">
                Home
              </a>
            </li>
            <li className="nav-item">
              <a href="#aboutUs" className="nav-link">
                About Us
              </a>
            </li>
            <li className="nav-item">
              <a href="#about" className="nav-link">
                Cleaning
              </a>
            </li>
            <li className="nav-item">
              <a href="#desinfection" className="nav-link">
                Disinfection
              </a>
            </li>
            <li className="nav-item">
              <a href="#client" className="nav-link">
                Clients
              </a>
            </li>
            <li className="nav-item">
              <a href="#pricing" className="nav-link">
                Pricing
              </a>
            </li>
            <li className="nav-item">
              <a href="#contact" className="nav-link">
                Contact
              </a>
            </li>
          </ul>
          {/* <button className="btn_custom btn btn_small text-capitalize  navbar-btn mr-3">Log In</button> */}
        </div>
      </div>
    </nav>
  );
}
