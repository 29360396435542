import React from 'react';
import emailjs from '@emailjs/browser';
import { useForm } from 'react-hook-form';

// eslint-disable-next-line
const EMAIL_REGEX = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}\s*$/;

export default function ContactUs() {
  // const [status, setStatus] = useState(undefined);

  // const form = useRef();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({ mode: 'onBlur' });
  const handleError = (errors) => {};

  const onSubmit = (formData) => {
    emailjs
      .send(
        process.env.REACT_APP_SERVICE_ID,
        process.env.REACT_APP_TEMPLATE_ID,
        formData,
        process.env.REACT_APP_PUBLIC_KEY
      )
      .then(
        (result) => {
          console.log(result.text);
          // setStatus({ type: 'success' });
        },
        (error) => {
          // console.log(error.text);
          // setStatus({ type: 'error', error });
        }
      );
    reset();
  };

  const registerOptions = {
    name: {
      required: 'Name is required',
      minLength: {
        value: 3,
        message: 'Name should be at least 3 characters long',
      },
      maxLength: {
        value: 24,
        message: 'Name should be max 24 characters long',
      },
    },
    email: { required: 'Email is required', pattern: { value: EMAIL_REGEX, message: 'Email is invalid' } },
    subject: {
      required: 'Subject is required',
      minLength: {
        value: 4,
        message: 'Subject should be at least 4 characters long',
      },
      maxLength: {
        value: 100,
        message: 'Subject should be max 100 characters long',
      },
    },
    message: {
      required: 'Message is required',
      minLength: {
        value: 10,
        message: 'Message should be at least 10 characters long',
      },
      maxLength: {
        value: 600,
        message: 'Name should be max 600 characters long',
      },
    },
    terms: { required: 'You must agree before submitting' },
  };

  // function sendEmail(e) {
  //   // e.preventDefault();

  //   emailjs
  //     .send(
  //       // 'brilliantservice', 'template_ktullbg', e.target, process.env.REACT_APP_PUBLIC_KEY
  //       process.env.REACT_APP_SERVICE_ID,
  //       process.env.REACT_APP_TEMPLATE_ID,
  //       form.current,
  //       process.env.REACT_APP_PUBLIC_KEY
  //     )
  //     .then(
  //       (result) => {
  //         console.log(result.text);
  //         alert('Message was Send');
  //       },
  //       (error) => {
  //         console.log(error.text);
  //         alert("I'm sorry message wasn't send, try again");
  //       }
  //     );
  //   e.target.reset();
  // }

  return (
    <section className="section_all " id="contact">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="section_title_all text-center">
              <div className="section_icons">
                <i className="mbri-bookmark"></i>
              </div>
              <h3 className="mt-3">
                Contact <span className="text_custom"> Us </span>
              </h3>
            </div>
          </div>
        </div>

        <div className="row mt-5">
          <div className="col-lg-4">
            <div className="contact_info_content text-center mt-3">
              <div className="minimal_contact_icon">
                <i className="mbri-mobile2 text_custom h2"></i>
              </div>
              <div className="mt-2">
                <p className="mb-0 con_head">Call Us Now</p>
                <p className="text-muted mb-0">+773-319-8087</p>
                <p className="text-muted mb-0">+847-630-2853</p>
              </div>
            </div>

            <div className="contact_info_content text-center mt-3">
              <div className="minimal_contact_icon">
                <i className="mbri-letter text_custom h2"></i>
              </div>
              <div className="mt-2">
                <p className="mb-0 con_head">Mail With Us</p>
                <p className="text-muted">brilliantservice@yahoo.com</p>
              </div>
            </div>

            <div className="contact_info_content text-center mt-3">
              <div className="minimal_contact_icon">
                <i className="mbri-pin text_custom h2"></i>
              </div>
              <div className="mt-2">
                <p className="mb-0 con_head">Location</p>
                <p className="text-muted">
                  Chicago, IL
                  <br />{' '}
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-8">
            <div className="landing_form_custom business_form_custom bg-white mt-3">
              <form onSubmit={handleSubmit(onSubmit, handleError)}>
                <div className="row">
                  <div className="col-lg-6">
                    <div className="form-group mt-2">
                      <input
                        className="form-control"
                        placeholder="Your Name *"
                        name="name"
                        type="text"
                        {...register('name', registerOptions.name)}
                      />
                      <small style={{ color: 'red', alignSelf: 'stretch' }}>
                        {errors?.name && errors.name.message}
                      </small>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group mt-2">
                      <input
                        className="form-control"
                        placeholder="Your Email *"
                        type="email"
                        name="email"
                        {...register('email', registerOptions.email)}
                      />
                      <small style={{ color: 'red', alignSelf: 'stretch' }}>
                        {errors?.email && errors.email.message}
                      </small>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-12">
                    <div className="form-group mt-2">
                      <input
                        className="form-control"
                        placeholder="Your Subject.."
                        type="text"
                        name="subject"
                        {...register('subject', registerOptions.subject)}
                      />
                      <small style={{ color: 'red', alignSelf: 'stretch' }}>
                        {errors?.subject && errors.subject.message}
                      </small>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-12">
                    <div className="form-group mt-2">
                      <textarea
                        rows="6"
                        className="form-control"
                        placeholder="Your message..."
                        type="text"
                        name="message"
                        {...register('message', registerOptions.message)}
                      ></textarea>
                      <small style={{ color: 'red', alignSelf: 'stretch' }}>
                        {errors?.message && errors.message.message}
                      </small>
                    </div>
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col-lg-12 text-center">
                    <input type="submit" className="btn btn_custom" value="Send Message" />
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
