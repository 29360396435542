import React from 'react';

export default function Home() {
  return (
    <section className="section_all back-img home-page-height " id="home">
      <div className="container">
        <div className="bg_overlay_cover_on ">
          <div className="box-set ">
            <div className="box box-1">
              <img src="/bri.png" alt="" className="star1" />
            </div>
            <div className="box box-2">
              <img src="/brilant.png" alt="" className="star2" />
            </div>
            <div className="box box-3">
              <img src="/bri.png" alt="" className="star3" />
            </div>
          </div>
        </div>
        <div className="row vertical_content_manage container">
          <div className="row">
            <div className="col-lg-12">
              <h1 className="text-white text-brilliant" style={{ fontSize: '430%', marginTop: '20%' }}>
                Brilliant Service
              </h1>
              <div className="mt-3">
                <h4 style={{ fontSize: '180%' }} className="text-white mb-0 pt-3">
                  Professional Cleaning and Covid Certified Disinfection.
                </h4>
                <div className="home_text_details">
                  <h4
                    style={{
                      paddingTop: '10%',
                      color: 'orange',
                      fontFamily: 'Papyrus,fantasy ',
                      fontStyle: 'italic',
                      fontWeight: 'bold',
                    }}
                    className=" pep home_subtitle mt-5 mb-0 text-center"
                  >
                    Do you want to have a vacation at your home???
                  </h4>
                  <h4
                    style={{
                      color: 'orange',
                      fontFamily: 'Papyrus,fantasy ',
                      fontStyle: 'italic',
                      fontWeight: 'bold',
                    }}
                    className="pep home_subtitle mt-2 mb-0 text-center"
                  >
                    It's possible !!!
                  </h4>
                  <h4
                    style={{ color: 'orange', fontFamily: 'Papyrus,fantasy ', fontStyle: 'italic', fontWeight: 'bold' }}
                    className=" pep home_subtitle mt-2 mb-0 text-center"
                  >
                    We will clean <span className="fr">4</span> you.
                  </h4>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="mt-3">
              <img
                style={{ height: '260px', width: '270px' }}
                src="Brilliant-Logo.png"
                alt=""
                className="img-fluid mx-auto d-block"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
