import React from 'react';

export default function Footer() {
  const today = new Date();
  const year = today.getFullYear();

  return (
    <section className="section_all bg_footer">
      <div className="container">
        <div className="row ">
          <img style={{ width: '100px' }} src="Brilliant-Logo.png" alt="" className="img-fluid mx-auto d-block" />
          <div className="col-lg-12">
            <div className="minimal_footer_header mt-3">
              <h5 className="text-white text-capitalize text-center">follow Us</h5>
            </div>
            <div className="footer_menu_list mt-3">
              <ul className="mb-0  text-center">
                <li className="list-inline-item">
                  <a href="/">Instagram</a>
                </li>
                <li className="list-inline-item">
                  <a href="https://www.yelp.com/biz/brilliant-cleaning-chicago">Yelp</a>
                </li>
                <li className="list-inline-item">
                  <a href="https://www.facebook.com/brilliantservice.org">Facebook</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-lg-12">
            <div className="text-center mt-3">
              <p className="footer_alt_cpy mb-0">
                {' '}
                &copy; {year} Brilliant Service. Design by{' '}
                <a style={{ color: 'white' }} href="https://kddziekan.com/">
                  KD Dziekan corp.
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
