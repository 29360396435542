import React from 'react';

export default function Services() {
  return (
    <section className="section_all toto" id="about">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="section_title_all text-center">
              <div className="section_icons">
                <i className="mbri-target"></i>
              </div>
              <h3 className="mt-3">
                What Type of Cleaning we do <span className="text_custom"> For you </span>
              </h3>
              {/* <p className="section_subtitle mx-auto text-muted">It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.</p> */}
            </div>
          </div>
        </div>

        <div className="row mt-5">
          <div className="col-lg-6">
            <div className="about_content_box_all text-center mt-3">
              <div className="about_detail">
                <div className="about_icon">
                  {/* <i className="text_custom mdi mdi-android-studio text-center"></i> */}
                </div>
                <p className="text-dark text-capitalize mt-3">Disinfection</p>
                <p className="text-dark text-capitalize mt-3">Residential </p>
                <p className="text-dark text-capitalize mt-3">Office </p>
                <p className="text-dark text-capitalize mt-3">Commercial </p>
                <p className="text-dark text-capitalize mt-3">After construction </p>
                <p className="text-dark text-capitalize mt-3">Move In/Out </p>
                <p className="text-dark text-capitalize mt-3">Workshop </p>
                {/* <p className="text-dark text-capitalize mt-3">And many more...</p> */}
              </div>
            </div>
          </div>

          <div className="col-lg-6">
            <div className="about_content_box_all text-center mt-3">
              <div className="about_detail">
                <div className="about_icon">{/* <i className="text_custom mdi mdi-code-tags text-center"></i> */}</div>
                {/* <h5 className="text-dark text-capitalize mt-3">Type of cleaning</h5> */}
                <p className="text-dark text-capitalize mt-3">Daily </p>
                <p className="text-dark text-capitalize mt-3">Weekly </p>
                <p className="text-dark text-capitalize mt-3">Every other week </p>
                <p className="text-dark text-capitalize mt-3">Monthly </p>
                <p className="text-dark text-capitalize mt-3">One time </p>
                <p className="text-dark text-capitalize mt-3">Holiday </p>
                <p className="text-dark text-capitalize mt-3">Occasional/Event </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
